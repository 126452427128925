body {
    background-color: #122026;
}

div,
a {
    color: #fefffe;
}

h1 {
    font-family: var(--font-bold);
}

.container {
    margin: 4%;
    text-align: left;
}

.policy {
    h3 {
        margin-top: 40px;
        margin-bottom: 1%;
    }
    p {
        font-size: 0.9em;
    }
}

.opac {
    opacity: .6;
}