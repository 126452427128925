body {
    background-color: #122026;
    text-align: center;
    font-family: var(--font-bold);
}

div,
a {
    color: #fefffe;
    text-decoration: none;
}

.theory-test {
    font-family: var(--font-bold);
    margin: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-container {
        width: 208px;
    }

    .logo {
        width: 100%;
        height: 100%;
    }

    .message-container {
        font-family: var(--font-bold);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 4% 0 0;
        width: 100%;

        .animation {
            width: 400px;
            height: 400px;
        }
    }

    .message {
        font-size: 4em;
        position: absolute;
        opacity: 0.9;
        max-width: 980px;
    }

    .watch {
        cursor: pointer;
        background-color: #FFF;
        border-radius: 40px;
        opacity: .9;
        position: absolute;
        bottom: 3%;

        &-wrapper {
            padding: 10px 20px;
            align-items: center;
            flex-direction: row;
            display: flex;
            justify-content: center;
            color: black;
        }
    }

    .watch-icon {
        margin-right: 10px;
        width: 32px;
    }

    .close {
        width: auto;
        margin: 24px 0;
        display: inline-block;
        cursor: pointer;
    }

    .app-types {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 4% 0 2%;
        width: 100%;
        max-width: 1100px;
        justify-content: space-around;

        .app-type {
            cursor: pointer;
            margin: 0 4%;
            max-width: 14vw;
            display: flex;
            font-family: var(--font-bold);
            flex-direction: column;
            padding-bottom: 1.5%;
            opacity: 0.3;

            &-active {
                border-bottom: 3px solid var(--colour-accent);
                opacity: 1;
            }

            img {
                height: 60px;
                margin-bottom: 18px;
            }
        }

        .app-type--motorcycles.app-type-active {
            border-bottom-color: var(--colour-accent);
        }
        .app-type--lgv-pcv.app-type-active {
            border-bottom-color: var(--colour-accent);
        }
        .app-type--adi.app-type-active {
            border-bottom-color: var(--colour-accent);
        }
    }

    .app-details {
        margin: 4% 0;
        display: flex;
        flex-direction: row;
        max-width: 980px;
        min-height: 557px;
        width: 100%;
        align-items: center;
        justify-content: center;

        .detail-left {
            text-align: left;
            padding-right: 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 3;
        }

        .detail-right {
            flex: 3;
        }

        .detail-small-title {
            font-family: var(--font-regular);
            font-size: larger;
            margin-bottom: 3%;
        }

        .detail-title {
            font-family: var(--font-bold);
            font-size: 3em;
        }

        .detail-title-sub {
            font-family: var(--font-bold);
            font-size: 2.4em;
            margin-bottom: 3%;
        }

        .detail-text {
            font-family: var(--font-regular);
            opacity: 0.8;
            margin-bottom: 6%;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 4% 0;
        a {
            opacity: 0.6;
            font-size: 0.8em;
            margin: 0 2%;
            &:hover {
                opacity: 1;
            }
        }
    }

    .foot-note {
        display: flex;
        justify-content: center;
        margin: 1%;
        opacity: 0.4;
        max-width: 670px;
        font-size: 0.8em;
    }

    .store {
        display: inline-block;
        overflow: hidden;
        margin-right: 20px;
        margin-bottom: 12px;
    }
    .store-ios-img {
        width: 180px;
        height: 60px;
    }

    .store-google-img {
        height: 60px;
    }

    .fade-in {
        animation: fadeIn ease 1s;
        -webkit-animation: fadeIn ease 1s;
        -moz-animation: fadeIn ease 1s;
        -o-animation: fadeIn ease 1s;
        -ms-animation: fadeIn ease 1s;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .coming-soon {
        font-family: var(--font-bold);
        font-size: 20px;
        margin: 10%;
        text-align: center;
        align-items: flex-start;
    }

    .app-car {
        background-image: url("../assets/images/phone-car.png");
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center right;
    }
}

@media screen and (max-width: 760px) {
    .theory-test {
        margin: 10%;
        .logo-container {
            width: 46vw;
        }
        .message-container .animation {
            width: 80%;
            height: auto;
        }
        .message {
            font-size: 2em;
            margin-top: 3px;
        }
        .app-type {
            img {
                margin-bottom: 0 !important;
            }
            span {
                display: none;
            }
        }
        .app-details {
            flex-direction: column;

            .detail-left,
            .detail-right {
                flex-grow: none;
            }
        }
        .app-car {
            background-size: 100%;
            background-position: -30px 30px;
            padding-top: 320px;
        }

        .coming-soon {
            justify-content: flex-start;
            align-items: center;
        }
    }
}
