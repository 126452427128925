:root {
    --font-regular: "Varela Round";
    --font-bold: "CeraProBold";
    --colour-accent: #F5A623;

    --colour-car: #F5A623;
    --colour-motorcycles: #0D73E9;
    --colour-lgv-pcv: #DD452C;
    --colour-adi: #A56F17;
}
